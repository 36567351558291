/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const ImageRow = ({ children }) => (
  <div
    sx={{
      display: "flex",
      justifyContent: "space-between",
      flexWrap: ["wrap", "nowrap"],
      mx: [-2, -3],
      my: [3, 4],
      "& > *": {
        flex: "1 0 auto",
        mx: [2, 3],
      },
      "&>.wide": {
        my: [3, 0],
        flex: ["0 0 calc(100% - 16px)", "2 0 auto"],
        order: [1, 2],
      },
    }}
  >
    {children}
  </div>
)

ImageRow.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImageRow
