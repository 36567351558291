/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Heading, Styled } from "theme-ui"

import { Container } from "components/layout"

const SpotlightTitle = ({ children, as }) => (
  <div
    sx={{
      bg: "dark",
      color: "background",
    }}
  >
    <Container>
      <Heading
        as={as}
        sx={{
          color: "background",
          my: 0,
          fontSize: [3, 4, 5],
        }}
      >
        <Styled.p
          as="span"
          sx={{ color: "background", display: "block", mb: 0 }}
        >
          Spotlight On
        </Styled.p>
        {children}
      </Heading>
    </Container>
  </div>
)

SpotlightTitle.defaultProps = {
  as: "h2",
}

SpotlightTitle.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default SpotlightTitle
