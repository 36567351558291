import React from "react"

const Buildings = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="77"
    height="74"
    viewBox="0 0 77 74"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M34.315 30.89l-15.31-7.655a2.552 2.552 0 00-2.287 0L1.408 30.89A2.552 2.552 0 000 33.171v38.275a2.552 2.552 0 002.552 2.552h7.655a2.552 2.552 0 002.551-2.552v-5.103a2.552 2.552 0 012.552-2.552h5.103a2.551 2.551 0 012.552 2.552v5.103a2.551 2.551 0 002.552 2.552h7.655a2.552 2.552 0 002.551-2.552V33.171a2.552 2.552 0 00-1.408-2.28zm-8.798 22.695h-15.31a2.552 2.552 0 110-5.104h15.31a2.551 2.551 0 110 5.104zm0-12.759h-15.31a2.551 2.551 0 110-5.103h15.31a2.551 2.551 0 110 5.103zm49.462-27.872L44.359.196a2.552 2.552 0 00-3.532 2.357v68.895A2.552 2.552 0 0043.38 74h7.655a2.551 2.551 0 002.552-2.552v-5.103a2.552 2.552 0 012.551-2.552h5.103a2.552 2.552 0 012.552 2.552v5.103A2.552 2.552 0 0066.344 74h7.655a2.551 2.551 0 002.552-2.552V15.312a2.552 2.552 0 00-1.572-2.358zm-8.635 40.633h-15.31a2.552 2.552 0 010-5.104h15.31a2.552 2.552 0 010 5.104zm0-12.759h-15.31a2.552 2.552 0 110-5.103h15.31a2.552 2.552 0 010 5.103zm0-12.758h-15.31a2.552 2.552 0 110-5.103h15.31a2.551 2.551 0 110 5.103z"
    ></path>
  </svg>
)

export default Buildings
