/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout, { Container } from "components/layout"
import Buildings from "components/svgs/buildings"
import Hero from "components/hero"
import ImageRow from "components/image-row"
import Infographic from "components/infographic"
import List from "components/list"
import SpotlightTitle from "components/spotlight-title"
import { TileInfo, TileLink, TileWrapper } from "components/tiles"
import Title from "components/title"

export const query = graphql`
  query BuildingsPageQuery {
    heroImage: file(relativePath: { eq: "hero/building.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    transportationImage: file(relativePath: { eq: "hero/transportation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    joyceCentre: file(relativePath: { eq: "building/joyce-centre.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    overlook: file(relativePath: { eq: "building/overlook.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auditorium: file(relativePath: { eq: "building/auditorium.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    benches: file(relativePath: { eq: "building/benches.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    roof: file(relativePath: { eq: "building/roof.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solar: file(relativePath: { eq: "building/solar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    study: file(relativePath: { eq: "building/study.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pipes: file(relativePath: { eq: "building/pipes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classroom: file(relativePath: { eq: "building/classroom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flowers: file(relativePath: { eq: "building/flowers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const BuildingsPage = ({ data }) => (
  <Layout
    pageTitle="Buildings & Grounds"
    description="Mohawk is committed to providing state-of-the art equipment and modern facilities that accommodate growth without increasing our carbon  footprint."
  >
    <Title icon={<Buildings />}>Buildings &amp; Grounds</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          Mohawk is committed to providing state-of-the art equipment and modern
          facilities that accommodate growth without increasing our carbon
          footprint. Since 2009, both the Fennell and Stoney Creek campuses have
          undergone significant renewal and growth.
        </Styled.p>
        <Styled.p>
          Redesigned as a welcoming place to live and learn, this renewal has
          created modernized spaces while enhancing energy efficiency,
          barrier-free access and more.
        </Styled.p>
        <Styled.p>
          Facilities like The Joyce Centre for Partnership &amp; Innovation at
          Mohawk's Fennell Campus provide opportunities for students and
          researchers to learn from leading-edge technologies and building
          design. The grounds incorporate native planting and climate-resilient
          design.
        </Styled.p>
      </div>
      <ImageRow>
        <Img className="wide" fluid={data.pipes.childImageSharp.fluid} />
        <Img fluid={data.classroom.childImageSharp.fluid} />
        <Img fluid={data.flowers.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Initiatives</Styled.h2>
        <List icon={<Buildings />}>
          <Fragment>
            High performance Zero Carbon and LEED<sup>&reg;</sup> buildings
          </Fragment>
          <Fragment>College-wide facility and infrastructure upgrades</Fragment>
          <Fragment>
            New 20–30 year Campus Master Plan (2019) which supports net zero and
            low carbon development
          </Fragment>
          <Fragment>
            Sustainable Landscape Plan (2016) for enhancing and developing
            outdoor spaces in accordance with the principles of sustainability
            and climate adaptation
          </Fragment>
        </List>
      </Container>
    </div>

    <Container wide={true} spaced={false}>
      <TileWrapper>
        <TileInfo bg="darkPrimary" color="background">
          <Styled.p>
            <Styled.h2 as="span">
              1<sup>st</sup>
            </Styled.h2>
            <br />
            building in Canada to achieve dual-certification in Zero Carbon
          </Styled.p>
        </TileInfo>
        <TileInfo bg="primary" color="background">
          <Styled.p>
            <Styled.h2 as="span">
              96,600 ft<sup>2</sup>
            </Styled.h2>
            <br />
            Zero Carbon certified building
          </Styled.p>
        </TileInfo>
        <TileInfo bg="accentTwo" color="dark">
          <Styled.p>
            <Styled.h2 as="span">
              71,705 ft<sup>2</sup>
            </Styled.h2>
            <br />
            of LEED<sup>&reg;</sup> certified buildings
          </Styled.p>
        </TileInfo>
        <TileInfo bg="secondary" color="dark">
          <Styled.p>
            <Styled.h2 as="span">2.25</Styled.h2>
            <br />
            acres of grounds managed with an organic approach
          </Styled.p>
        </TileInfo>
      </TileWrapper>
    </Container>

    <SpotlightTitle>
      The Joyce Centre for Partnership &amp; Innovation
    </SpotlightTitle>
    <Hero fluid={data.joyceCentre.childImageSharp.fluid} />
    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          In August 2018, Mohawk College opened The Joyce Centre for Partnership
          &amp; Innovation—a 96,700-square-foot net zero carbon building.
        </Styled.p>
        <Styled.p>
          Highly energy efficient, The Joyce Centre's solar photovoltaic (PV)
          system is designed to generate 721,000 kWh of clean, renewable
          electricity annually. A geothermal system heats and cools the
          building.
        </Styled.p>
        <Styled.p>
          The addition of this building supports a growing student population
          but does not add any corporate carbon emissions.
        </Styled.p>
      </div>
      <ImageRow>
        <Img fluid={data.overlook.childImageSharp.fluid} />
        <Img fluid={data.auditorium.childImageSharp.fluid} />
        <Img className="wide" fluid={data.benches.childImageSharp.fluid} />
      </ImageRow>
      <ImageRow>
        <Img className="wide" fluid={data.roof.childImageSharp.fluid} />
        <Img fluid={data.solar.childImageSharp.fluid} />
        <Img fluid={data.study.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "accentOne", color: "background" }}>
      <Container>
        <div
          sx={{
            display: ["block", "block", "flex"],
            py: 4,
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Infographic value="1,980" color="background">
              solar panels installed as part of this project
            </Infographic>
            <Infographic value="68" color="background">
              geothermal wells dug to a depth of 605 ft for heating and cooling
            </Infographic>
            <Infographic value="115%" color="background">
              of energy needed to operate generated in the first year of
              operations
            </Infographic>
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Infographic value="730,000" color="background">
              kWh generated annually through solar PV
            </Infographic>
            <Infographic value="228,000" color="background">
              liters of rainwater runoff collected through underground cisterns
            </Infographic>
            <Infographic value="460" color="background">
              sensor-controlled LED lights to reduce energy demand
            </Infographic>
          </div>
        </div>
      </Container>
    </div>

    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink
        to="/transportation"
        image={data.transportationImage.childImageSharp.fluid}
      >
        Transportation
      </TileLink>
    </Container>
  </Layout>
)

export default BuildingsPage
