/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Text, Box } from "theme-ui"

const Infographic = ({ value, icon, children, color }) => (
  <Box
    sx={{
      color,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      my: 3,
    }}
  >
    <div
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        mb: 3,
      }}
    >
      {icon && (
        <div sx={{ mr: 3, display: "flex", alignItems: "center" }}>{icon}</div>
      )}

      <Text sx={{ fontSize: [5, 6, 7], fontWeight: "bold", lineHeight: 1 }}>
        {value}
      </Text>
    </div>
    <Text sx={{ fontWeight: "bold" }}>{children}</Text>
  </Box>
)

Infographic.defaultProps = {
  color: "darkPrimary",
}

Infographic.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
}

export default Infographic
